<template>
	<div class="main">
		<div class="top">
			<p>收益提现</p>
			<span>查看近期收益状况并提现到账户</span>
		</div>
		<div class="center">
			<p class="p">
				<span>收益概况</span>
			</p>
			<div class="acceptBox" v-loading="loading2">
				<!-- 收益提现区域开始 -->
				<div class="left">
					<p>可提现（元）</p>
					<p>{{balance}}</p>
					<el-button class="button" type="primary" @click="dialogVisible = true">收益提现</el-button>
					<p>每周四9:00-21:00可提现</p>
					<el-link class="link" type="primary" :underline="false" @click="goAgentAcceptList()">提现记录</el-link>
				</div>
				<!-- 收益提现区域结束 -->
				<!-- 折线图展示开始 -->
				<div id="echar" class="right"></div>
				<!-- 折线图展示结束 -->
			</div>
		</div>
		<div class="bottom">
			<!-- 收益详情列表标题头开始 -->
			<p class="p">
				<span>收益详情</span>
				<span>每日17:00更新昨日收益，最晚24:00更新</span>
			</p>
			<!-- 收益详情列表标题头结束 -->
			<!-- 收益详情列表开始 -->
			<el-table :data="data" v-loading="loading">
				<el-table-column type="index" label="序号" align="center" width="100"></el-table-column>
				<el-table-column prop="showNum" label="广告展示次数" align="center"></el-table-column>
				<el-table-column prop="profitDate" label="收益日期" align="center"></el-table-column>
				<el-table-column prop="profitNum" label="日收益总数(元)" align="center" width="200">
					<template slot-scope="scope">
						<!-- <span>{{scope.row.profitNum == '0' ? '无' : $util.setMoney(scope.row.profitNum)}}</span> -->
            <span>{{scope.row.profitNum == '0' ? '无' : $util.setMoney(scope.row.profitNum *10)}}</span>
					</template>
				</el-table-column>
			</el-table>
			<!-- 收益详情列表结束 -->
			<!-- 收益详情列表分页开始 -->
			<div class="block">
				<el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
					:current-page="currpage" :page-sizes="[10, 50, 100]" :page-size="pagesize" background
					layout="total,prev, pager, next,sizes,jumper" :total="CountNum" style="margin-top:20px">
				</el-pagination>
			</div>
			<!-- 收益详情列表分页结束 -->
		</div>
		<!-- 收益体现弹框开始 -->
		<el-dialog title="收益提现" :visible.sync="dialogVisible" width="600px" style="text-align: left;">
			<div class="popUpBox">
				<div class="popUpBoxLeft" :class="action ? 'action' : ''" @click="action = true,action2 = false">
					<p>提现到银行卡</p>
					<p v-if="!!bankInfo">{{bankInfo.bankName + '(' + bankInfo.cardNum + ')'}}</p>
					<p v-else style="color: red;">暂未绑定银行卡</p>
					<div><img src="../../assets/icon_plan.png"></div>
				</div>
				<div v-if =" Status == 'agentAdv'"  class="popUpBoxRight" :class="action2 ? 'action2' : ''" @click="action2 = true,action = false">
					<p>收益转广告余额 </p>
					<p>{{balance}}</p>
					<span>当前余额</span>
					<div><img src="../../assets/icon_card.png" style="margin-top: 7px;"></div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false,visible = false">取 消</el-button>
				<el-popover placement="top" width="160" trigger="manual" v-model="visible">
					<p>暂未添加银行卡，是否现在添加新行卡？</p>
					<div style="text-align: right; margin: 0">
						<el-button size="mini" type="text" @click="visible = false">否</el-button>
						<el-button type="primary" size="mini"
							@click="visible = false,dialogVisible = false,dialogFormVisible = true">是</el-button>
					</div>
					<!-- <el-button slot="reference" type="primary" @click="ok" :disabled="balance <= 0">确 定</el-button> -->
          <el-button slot="reference" type="primary" @click="ok" >确 定</el-button>
				</el-popover>
			</span>
		</el-dialog>
		<!-- 收益体现弹框结束 -->
		<!-- 添加银行卡弹框开始 -->
		<el-dialog title="添加银行信息" :visible.sync="dialogFormVisible" width="600px" :close-on-click-modal="false">
			<el-form :model="form" :inline="true">
				<el-form-item label="银行开户名：">
					<el-input v-model="form.cardName" placeholder="请输入开户名(例如:张三,李四等)" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="银行开户行：">
					<el-input v-model="form.bankName" placeholder="请输入开户行(例如:工商银行,建设银行等)" style="width: 300px;">
					</el-input>
				</el-form-item>
				<el-form-item label="银行卡卡号：">
					<el-input v-model="form.cardNum" placeholder="请输入银行卡卡号(例如:6222****)" style="width: 300px;">
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="addCard()">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 添加银行卡弹框结束 -->
	</div>
</template>

<script>
	import {
		agentadvprofitinfo, //获取代理广告收益明细记录接口
		advProfitShow, //获取余额和收益图表和银行卡接口
		advWithdrawal, //代理广告收益提现
		advTransferBalance, //代理广告收益转余额
		SetBankInfo //设置银行卡接口
	} from '@/api/agentApi.js'
  import {
  	mapGetters
  } from "vuex";
	export default {
    props: ["isCollapse"],
		data() {
			return {
				data: [], //表格数据
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
				balance: '0', //余额
				dialogVisible: false, //收益提现弹框
				visible: false, //提示添加银行卡
				dialogFormVisible: false, //添加银行卡弹框
				bankInfo: {}, //银行卡信息
				form: {}, //添加的银行卡信息
				loading: false, //表格加载框
				loading2: false, //折线图加载框
				action: true, //选中提现到银行卡
				action2: false, //选中收益转余额
			}
		},
		created() {
			this.agentadvprofitinfo(1, this.pagesize); // 获取收益详情记录列表
			this.advProfitShow(); // 获取余额和收益图表和银行卡
		},
    computed: {
    	...mapGetters(["Status"]),
    },
		methods: {
			drawChart(x, y) { //折线图展示
				var dom = document.getElementById('echar');
				var echar = this.$echarts.init(dom);
        console.log(x)
        console.log(y)
				var option = {
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['收益展示']
					},
					xAxis: {
						type: 'category',
						data: x,
						axisTick: {
							show: false
						},
					},
					yAxis: {
						type: 'value',
						axisPointer: {
							show: true,
							triggerTooltip: false,
							label: {
								formatter: val => val.value.toFixed(1)
							}
						},
						axisLabel: {
							formatter: val => val.toFixed(1)
						},
						axisTick: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: '#e8e8e8'
							}
						},
						// min: 0,
						// max: 100000
					},
					series: [{
						name: '收益展示',
						data: y,
						type: 'line',
						itemStyle: {
							color: '#1890FF'
						}
					}]
				};
				echar.setOption(option);
				setTimeout(function() {
					window.onresize = function() {
						echar.resize();
					}
				}, 200)
			},
			advProfitShow() { // 折线图和银行卡信息数据
				this.loading2 = true; //加载框
				advProfitShow().then(res => { //请求
					this.bankInfo = res.data.bankInfo; //银行卡信息
					this.balance = res.data.profitNum; //收益余额
					if (!!this.bankInfo.cardNum) { //当有银行卡卡号时
						this.bankInfo.cardNum = this.bankInfo.cardNum.slice(-4); //截取显示后4位
					}
					var x = res.data.charData.monthArr; // X轴数据
					var y = res.data.charData.profitArr; // Y轴数据
					this.drawChart(x, y); //渲染折线图
					this.loading2 = false; //关闭加载框
				})
			},
			goAgentAcceptList() {
				this.$router.push('agentAcceptList');
			},
			async addCard() { //添加银行卡信息
				const data = { //请求的银行卡信息
					cardName: this.form.cardName,
					bankName: this.form.bankName,
					cardNum: this.form.cardNum
				};
				let res = await SetBankInfo(data); //发送请求
				if (res.status.code == 1) { //判断是否成功
					this.$message.success("添加成功"); //成功提醒
					this.dialogFormVisible = false; //关闭弹窗
					this.advProfitShow(); // 重新获取余额和收益图表和银行卡
				} else {
					this.$message.error("添加失败:" + res.status.msg); //失败提醒
				}
			},
			agentadvprofitinfo(currpage, pageSize) { // 收益详情列表
				this.loading = true;
				const data = {};
				data["page"] = currpage; //	当前页数
				data["limit"] = pageSize; // 每页条数
				agentadvprofitinfo(data).then(res => {
					this.data = res.data.List // 收益详情列表
					this.CountNum = parseInt(res.data.CountNum); // 总条数
					this.currpage = currpage; //分页显示当前页
					this.pagesize = pageSize;
					this.loading = false;
				})
			},
			async ok() { //收益提现确定按钮
				if (this.action) {
					if (!this.bankInfo) {
						this.visible = true;
					} else {
						let res = await advWithdrawal({
							num: this.balance
						}); //提现到银行卡接口
						if (res.status.code == 1) {
							this.$message.success("提现到银行卡成功"); //成功提醒
							this.dialogVisible = false; //关闭弹框
							this.advProfitShow(); // 重新获取余额和收益图表和银行卡
						} else {
							this.$message.error("提现失败:" + res.status.msg); //失败提醒
						}
					}
				} else {
					let res = await advTransferBalance({
						num: this.balance
					}); //收益转余额接口
					if (res.status.code == 1) {
						this.$message.success("收益成功转到余额"); //成功提醒
						this.dialogVisible = false; //关闭弹框
						this.advProfitShow(); // 重新获取余额和收益图表和银行卡
					} else {
						this.$message.error("收益转到余额失败:" + res.status.msg); //失败提醒
					}
				}
			},
			handleCurrentChange(num) { // 分页
				this.agentadvprofitinfo(num, this.pagesize);
			},
			handleSizeChange(num) { //分页设置显示条数
				this.agentadvprofitinfo(1, num);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.main {
		background-color: #f0f2f5;
	}

	.top {
		width: 100%;
		height: 10vh;
		text-align: left;
		padding-left: 4%;
		background-color: white;
		margin-bottom: 2vh;

		p {
			font-size: 20px;
			font-weight: bold;
		}

		span {
			font-size: 14px;
			color: gray;
		}
	}

	.center {
		width: 98%;
		margin: 0 auto;
		background-color: white;
		margin-bottom: 2vh;
		text-align: left;

		.p {
			height: 40px;
			line-height: 40px;
			border-bottom: 1px solid #E8E8E8;
			color: #1890FF;

			span {
				margin-left: 10px;
				height: 40px;
				line-height: 40px;
				border-bottom: 2px solid #1890FF;
				display: inline-block;
			}
		}

		.acceptBox {
			display: flex;
			height: 30vh;

			.left {
				width: 30%;
				height: 30vh;
				text-align: center;
				padding-top: 50px;

				p:nth-child(1) {
					font-size: 16px;
					font-weight: normal;
					margin: 0;
					margin-bottom: 20px;
				}

				p:nth-child(2) {
					font-size: 40px;
					font-weight: bold;
					color: #1890FF;
					margin: 0;
					margin-bottom: 20px;
				}

				p:nth-child(4) {
					font-size: 12px;
					color: gray;
				}

				.button {
					width: 35%;
					min-width: 100px;
				}

				.link {
					float: right;
				}
			}

			.right {
				width: 70%;
				height: 30vh;
				margin: 0;
				padding: 0;
			}
		}
	}

	.bottom {
		width: 98%;
		margin: 0 auto;
		background-color: white;

		.p {
			display: flex;
			justify-content: space-between;
			padding-left: 10px;
			padding-right: 10px;
			height: 40px;
			line-height: 40px;
			border-bottom: 1px solid #E8E8E8;
			color: #1890FF;

			span {
				margin-left: 10px;
				height: 40px;
				line-height: 40px;
				border-bottom: 2px solid #1890FF;
				display: inline-block;
			}

			span:nth-child(2) {
				border: none;
			}
		}
	}

	.popUpBox {
		display: flex;
		justify-content: center;

		.popUpBoxLeft {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			align-content: space-between;
			width: 180px;
			height: 200px;
			border: 1px solid #E8E8E8;
			border-radius: 5px;
			margin-right: 40px;

			p:nth-child(1) {
				font-size: 16px;
			}

			p:nth-child(2) {
				margin-top: -20px;
				font-size: 18px;
				color: #1890FF;
			}

			div {
				background-color: #1890FF;
				width: 180px;
				height: 60px;
				line-height: 80px;
				text-align: center;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
			}

			&:hover {
				cursor: pointer;
			}
		}

		.popUpBoxRight {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			align-content: space-between;
			position: relative;
			width: 180px;
			height: 200px;
			border: 1px solid #E8E8E8;
			border-radius: 5px;

			p:nth-child(1) {
				font-size: 16px;
				width: 100%;
				text-align: center;
			}

			p:nth-child(2) {
				margin-top: -20px;
				font-size: 26px;
				font-weight: bold;
				color: #1890FF;
			}

			span {
				font-size: 12px;
				color: gray;
				position: absolute;
				top: 48%;
				right: 10%;
			}

			div {
				background-color: #19BE6B;
				width: 180px;
				height: 60px;
				line-height: 70px;
				text-align: center;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
			}

			&:hover {
				cursor: pointer;
			}
		}

		.action {
			border: 1px solid #1890FF;
		}

		.action2 {
			border: 1px solid #19BE6B;
		}
	}
</style>
